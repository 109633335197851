.cards{
  padding: 20px 0rem 0px;
  transform: scale(1);
  background: #fbfbfd;
  
}

.cards-section{
  background-color: #fbfbfd;
  padding: 4rem;
  transform: scale(1);
}

.video-section {
  background-color: #fbfbfd;
  padding: 4rem;
  transform: scale(1);
}
.service-header{
  font-size: 30px;
  color: #3f3f3f;
  letter-spacing: 0.3px;
  font-weight: bold;
}

h1 {
  
  text-align: center;
  color: #3f3f3f;
  top: 20px;
  font-size: 30px;
}
.cards__item{
  margin-top: 20px;
  
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1500px;
  width: 90%;
  margin: 0 auto;
  
  
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards-items {
  margin-bottom: 24px;
  
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #fbfbfd;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 0px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic {
  position: relative;
  width: 100%;
  padding-top: 80%;
  overflow: hidden;
  /* filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.1)); */
}

.cards__item__pic::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards-item-img {
  position: absolute;
  top: 5px;
  right: 0;
  bottom:0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards-item-img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 10px 40px 20px;
  text-align: center;
}

.cards__item__text {
  color: #3f3f3f;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  font-family: 'Noto sans', sans-serif;
  letter-spacing: 0.7px;
  
}
.cards__item__txt {
  color: #3f3f3f;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  font-family: 'Noto sans', sans-serif;
  letter-spacing: 0.7px;
}
@media only screen and (min-width: 1024px) {
  .cards-items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards-item {
    margin-bottom: 2rem;
  }
}
