.visit-us-header h1{
  letter-spacing: 0.3px;
    text-align: left;
    font-weight: bold;
}

.contact-section{
    padding: 4rem 0;
    background-color: rgb(255, 255, 255);
}
.visit-location {
    color: #6d797f;
    font-size: 14px;
  letter-spacing: 1px;
}

.contact-us-header h1{
   letter-spacing: 0.3px;
    text-align: left;
    font-weight: bold;
}

.contact-phone{
    color: #6d797f;
    font-size: 14px;
    letter-spacing: 1px;
    

}

.contact-form input {
    background-color: #f8fbff;
    border-radius: none;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  transition: 0.5s;
  
  text-decoration: none;
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;
}

.contact-form textarea {
    background-color: #f8fbff;
    border-radius: none;
    padding: 8px 20px;
    font-size: 14px;
    letter-spacing: 1px;

    transition: 0.5s;
    text-decoration: none;
    background-color: Transparent;
    background-repeat:no-repeat;
    cursor:pointer;
    overflow: hidden;
}
.contact-mail {
    color: #6d797f;
    font-size: 14px;
    line-height: 30px;
  letter-spacing: 1px;

}

.btn-message .btn-send {
    width: 170px;
    color: #30A5B2;
  border-color: #30A5B2;
  background-color: #fbfbfd;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  transition: 0.5s;
  border: 1px solid #30A5B2;
  text-decoration: none;
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;
}
.btn-message  .btn-send :hover {
    color: #fff;
  background-color: #30A5B2;
}