* {
    
    margin: 0;
    padding: 0;
  }
  .home,
  .services,
  .about,
  .blog,
  .contact,
  .work,
  .error {
    height: 40vh;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    object-fit: contain;
    font-weight: 30px;

  }

  .header-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1
  }

  .about-header,
  .blog-header,
  .contact-header,
  .services-header,
  .work-header {
    margin-top: 50px;
    font-size: 3rem;
    color: #eee;
    font-family: 'Noto sans',sans-serif;
    font-style: inherit;
    /* text-transform: uppercase; */
    font-weight: bold;
    
  }

  .content-error{
    text-align: center;  

  }

  
  .content-error .btn-get-back{
    width: 140px;
    color: #30A5B2;
  border-color: #30A5B2;
  background-color: #fbfbfd;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  transition: 0.5s;
  border: 1px solid #30A5B2;
  text-decoration: none;
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;
  }

  .content-error .btn-get-back:hover {
    color: #fff;
  background-color: #30A5B2;
  }

.error-header {
    font-size: 140px;
    color: #318C9B;
  }

 .cards .error-header {
  font-family: 'Noto sans',sans-serif;
    font-style: inherit;
    text-transform: uppercase;
    font-weight: bold;
    padding: 6rem;
  transform: scale(1);
  background: #f5f8fd;
  }

  h1{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }