
  .embed-responsive .embed-responsive-item {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 7vh;
  }

  .shoishob-img {
    
    margin: auto;
    margin-top: 50px;
    height: 80vh;
    display: flex;
    justify-content: center;
    
  }
  