@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400&family=Source+Sans+Pro:wght@500&family=Ubuntu:ital,wght@0,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
}

:root {
    --mainColor: #fff;
    --mainColorLight: #30A5B2;
    --secondaryColor:#318C9B;
    --textColor: #000000;
    --collapsColor: #30A5B2;
}


.nav-logo {
    padding: 0 30px;
}
.nav-link {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-family: 'Noto Sans', sans-serif;
    font-size: 15px;
    
}
.active {
    color: #30A5B2;
}

.nav-link:hover {
    color: var(--secondaryColor);
}

.active-link{
    color: var(--secondaryColor);
}