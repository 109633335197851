.about-text {
  font-size: 15px;
  background-color: #fbfbfd;
  padding: 4rem;
  transform: scale(1);
}
.about-text h1{
  font-size: 20px;
  line-height: 1.5;
  color: #525252;
  font-family: 'Noto Sans', sans-serif;
  
}

.about-cat h1{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.about-cat p{
  font-size: 20px;
  text-align: center;
}
p {
  font-weight: lighter;
  font-size: 14px;
  color: #525252;
}

.about-cat h1{
  font-size: 30px;
  color: #3f3f3f;
  letter-spacing: 0.3px;
  font-weight: bold;
}
.our-team {
  text-align: center;
  position: relative;
  color: #fff;
  text-decoration: none;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;

}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.our-team img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 0px;
  overflow: hidden;
}

.our-team .content {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 45%;
  transform: perspective(400px) rotateX(-45deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  opacity: 0;
}

.our-team:hover .content {
  opacity: 1;
  border-radius: 0px;
  transform: perspective(400px) rotateX(0deg);
}

.our-team .content .title {
  margin: -100px 0 10px;
  display: block;
  font-size: 20px;
  font-weight: 100;
  font-family: 'Noto Sans', sans-serif;
}

.our-team .content .post {
  margin: 10px 0 25px;
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.our-team .social-icons {
  margin: 0px;
  padding: 0;
  list-style: none;
}

.our-team .social-icons li {
  display: inline-block;
  
}

.our-team .social-icons li .social-icon-link {
  display: block;
  color: #fff;
  font-size: 17px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 20px;
  margin-right: 5px;
  transition: all 0.5s ease 0s;
  position: relative;
}

.our-team .social-icons li .facebook {
  background-color: #4267B2;
}

.our-team .social-icons li .instagram {
  background-image: linear-gradient(to bottom left, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #F77737, #FCAF45, #FFDC80 );
}

.our-team .social-icons li .linkedin {
  background-color: #0e76a8;
}

.our-team .social-icons li .social-icon-link:hover {
  background-image: white;
  color: black;
}

 @media screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }
  
} 
/* 
.card-about {
    
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    transition: 0.4s;
    background: #fff;
   
    
    position: center;
    transition: .6s ease-out;
  
}

.card-about {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  } */
