.footer-container {
    background-color: #318C9B;
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom:0;
    width:100%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    color: #fff;
  }
  
  .footer-logo > div {
    font-family: 'Noto sans', sans-serif;
  }
  
  .footer-logo-heading {
    margin-bottom: 24px;
    font-size: 14px;
  }
  

  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
  }

  .footer-logo .social-media .social-icon-link {
    display: block;
    color: #fff;
    background-color: rgba(0, 0, 0, .2);
    font-size: 17px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 20px;
    margin-right: 5px;
    transition: all 0.5s ease 0s;
    position: relative;
  }

  .footer-logo .social-media .facebook:hover {
    color: #fff;
    background-color: #4267B2;
  }

  .footer-logo .social-media .instagram:hover {
    background-image: linear-gradient(to bottom left, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #F77737, #FCAF45, #FFDC80 );
    background-color: #fff;
  }

  .footer-logo .social-media .youtube:hover {
    color: #fff;
    background-color: #c4302b;
  }

  .footer-logo .social-media .twitter:hover {
    color: #fff;
    background-color: #00acee;
  }

  .footer-logo .social-media .linkedin:hover {
    color: #fff;
    background-color: #0e76a8;
  }

  .footer-info {
    justify-content: center;
    align-items: center;
    display: inline-block;
    color: #fff;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.7px;
  }

.footer-info-address{
  color: #fff;
  padding: 5px;
  font-size: 14px;
  letter-spacing: 0.7px;
}

  .footer-end .footer-end-rights {
    padding: 10px;
    color: #fff;
    font-size: 14px;
  letter-spacing: 0.7px;
    
  }

  @media only screen and (max-width: 1024px) {

  }

  @media only screen and (min-width: 1024px) {
    .footer-info {
      justify-content: center;
      align-items: center;
      display: inline-block;
      color: #fff;
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }
  