.ser-img .animated {
    animation: right-left 2s ease-in-out infinite alternate-reverse both;
    width: 400px;
    text-align: center;
    align-items: center;
    margin-left: 20%;
}

.services-section {
  background-color: #fbfbfd;
  padding: 2rem;

}
@-webkit-keyframes right-left {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @keyframes right-left {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

.services-section .service-name h1{
  font-weight: bold;
  color: #3f3f3f;
  font-size: 30px;
  letter-spacing: 0.3px;
    
}
.sub-cat h4{
  font-family: 'Noto Sans', sans-serif;
  color: #3f3f3f;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.sub-cat{
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: #525252;
}

.service-text{
  
    text-align: justify;
    text-justify: auto;
    text-justify: inter-word;
}