.container-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.container-sub {
    color: #fff;
    width: 90vh;
    font-size: 17px;
    line-height: 25px;
}

.content {
    height: 100vh;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.content > h1 {
    color: #ffffff;
    font-size: 50px;
    justify-content: center;
}

.btns {
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    width: 150px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 20px;
    transition: 0.5s;
    color: #eee;
    border: 0.5px solid #eee;
    text-decoration: none;
    background-color: Transparent;
    background-repeat:no-repeat;
    cursor:pointer;
    overflow: hidden;
}


.btns:hover {
    background: #eee;
    color: #318C9B;
}

@media only screen and (max-width: 1024px) {
    .content > h1 {
        color: #ffffff;
        font-size: 30px;
    }
}
