
.logo-area {
    height: 15vh;
    display: block;
    margin-top: 5vh;
    align-items: center;
    text-align: center;
    align-content: center;
    padding: 0 px;
    position: relative;
    overflow: hidden;
    
}

.client-header{
    font-size: 30px;
    color: #3f3f3f;
    letter-spacing: 0.3px;
    font-weight: bold;
  }
  
.home-section .logo-section {
    background: #e9e9e9;
    padding: 4rem;
    transform: scale(1);
  }


.logo-icon {
    margin: 20px 20px;
    height: 60px;
    position: relative;
    transition: 0.5s;
    object-fit: cover;
    transform: scalez(1);
    margin-bottom: 30px;
     /* Firefox 10+ */
    
    
    
}

.logo-icon:hover {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
    transform: scale(1.2);
}

.slick-prev, .slick-next {
    width: 0px;
    height: 0px;
    background-color: #fff;
    margin-top: -10px;
    color: #fff;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    color: #fff;
    outline: none;
    background: #fff;
}

.slick-prev:before, .slick-next:before {
    color: #000;
    opacity: 0.3 ;
}

.slick-prev {
    z-index: 100;
    left: 20px !important;
}

.slick-next {
    z-index: 100;
    right: 20px !important;
}


.card-logo{
    position: relative;

    padding: 0 20%;   
}
@media screen and (max-width: 990px) {
    .logo-icon {
        height: 25px;
        margin: 8px 15px;
    }
    
  } 
