.videos {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 25%;
  -moz-column-width: 25%;
  column-width: 25%;
  padding: 0rem;
  /* width: 800px; */
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;

}
.video-cards{
  background-color: #fff;
  padding: 4rem;
  transform: scale(1);
}
.video-item-link {
  text-align: center;
  position: relative;
  color: #fff;
  text-decoration: none;
  
}

.section-header{
  font-size: 30px;
  color: #3f3f3f;
  letter-spacing: 0.3px;
  font-weight: bold;
}


.video-item-link .content {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-align: center;
  padding-top:0%;
  transform: perspective(400px) rotateX(0deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  opacity: 0;
  color: #fff;
}

.video-item-link:hover .content {
  opacity: 4;
  transform: perspective(0px) rotatey(0deg);
  transform: scale(1);
  
  
}

.video-item-link img {
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  overflow: hidden;
  
}

.video-item-link .title {
  margin: 0px 0 10px;
  display: block;
  font-size: 25px;
  font-weight: 500;
  font-family: 'Ubuntu',sans-serif;
}

.video-item-link .content .video-details {
  margin: 10px 0 25px;
  display: block;
  font-size: 15px;
  font-weight: 500;
} 

.view-more {
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-more .btn-view-more{
  width: 120px;
  color: #30A5B2;
  border-color: #30A5B2;
  background-color: #fbfbfd;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  transition: 0.5s;
  border: 1px solid #30A5B2;
  text-decoration: none;
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;
  

}

.view-more .btn-view-more:hover{
  color: #fff;
  background-color: #30A5B2;
  
}

@media screen and (max-width: 990px) {
  .videos {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 50%;
    -moz-column-width: 50%;
    column-width: 50%;
    padding: 0rem;
  }
  
} 
@media screen and (max-width: 600px) {
  .videos {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0rem;
  }
  
} 